import { centralizedAuth } from '@cimpress/simple-auth-wrapper';
 
export const CLIENT_ID = '5gURdbZ96hlOmOJQJyacXdTumsNPwYAS';

const options = { clientID: CLIENT_ID };

const auth = new centralizedAuth(options);

$(document).ready(function() {
    auth.ensureAuthentication(window.location)
        .then(isAuthenticated => {
            if (isAuthenticated) {
                window.location.href = 'app.html?token=' + auth.getToken();
            }
            // if not isAuthenticated then page is in the process of redirecting to login.  leave authenticating state true
        })
        .catch(error => {
            // RARE.
            console.error(error);
        });      
});

export default auth;